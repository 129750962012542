@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary-color: #085c84;
}

* {
  font-family: "Montserrat", sans-serif !important;
}
html,
body {
  scroll-behavior: smooth;
}

.main-color > * {
  color: var(--primary-color) !important;
}
.main-color {
  color: var(--primary-color) !important;
}
.dropdown-item,
.nav-link {
  font-size: 20px;
  font-weight: 700;
}

.main-bg {
  background-color: var(--primary-color) !important;
}
.bg-image-top {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  transition: background-image 1s ease-in-out;
  /* background-blend-mode: overlay; */
  object-fit: cover;
  padding-top: 75px;
  /* z-index: -2; */
  position: relative;
}

.bg-image-team {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bg-image-team::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0
  ); /* Adjust the opacity value (0.5) as needed */
  backdrop-filter: blur(3px); /* Adjust the blur value (5px) as needed */
  z-index: 1;
}
.bg-image-team > * {
  z-index: 22;
}
#mobility {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  position: relative;
}

#mobility::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.358
  ); /* Adjust the opacity value (0.5) as needed */
  backdrop-filter: blur(5px); /* Adjust the blur value (5px) as needed */
  z-index: 1;
}
#mobility > * {
  z-index: 22;
}
#ar {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  position: relative;
}

#ar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #085d8454; /* Adjust the opacity value (0.5) as needed */
  backdrop-filter: blur(5px); /* Adjust the blur value (5px) as needed */
}
#ar > * {
  z-index: 2;
}

.team-content {
  background-color: #085d84b1; /* Adjust the opacity value (0.5) as needed */
  z-index: 1;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.53);
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  transition: all 0.3s ease-in-out;
}
.team-content:hover {
  transform: scale(1.1);
  backface-visibility: hidden;
  box-shadow: 0px 0px 10px 2px #9fd7f1;
  z-index: 2 !important;
}
.service-div-sm {
  transition: all 0.3s ease-in-out;
}
.service-div-sm:hover {
  /* transform: scale(1.1); */
  box-shadow: 0px 0px 10px 5px #9fd7f1;
  z-index: 2 !important;
  cursor: pointer;
  /* border: none !important; */
}
.p-tag-dark {
  color: #034667 !important;
}

/* .binary-number {
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.5rem;
  z-index: 2;
}

.binary-number:nth-child(1) {
  top: 10%;
  left: 20%;
}

.binary-number:nth-child(2) {
  top: 40%;
  left: 70%;
} */

.div-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  transition: background-image 1s ease-in-out;
  /* background-blend-mode: overlay; */
  object-fit: cover;
  clip-path: polygon(
    0% 0%,
    68.3% 0%,
    73.6% 4.65%,
    100% 5.2%,
    99.8% 99.45%,
    28.95% 99.25%,
    17.3% 89.55%,
    0% 89.55%
  );
}
.div-img-v {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  transition: background-image 1s ease-in-out;
  /* background-blend-mode: overlay; */
  object-fit: cover;
  clip-path: polygon(
    0% 100%,
    68.3% 100%,
    73.6% 95.35%,
    100% 94.8%,
    99.8% 0.55%,
    28.95% 0.75%,
    17.3% 10.45%,
    0% 10.45%
  );
}
.div-img-reverse {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  transition: background-image 1s ease-in-out;
  /* background-blend-mode: overlay; */
  object-fit: cover;
  clip-path: polygon(
    100% 0%,
    31.7% 0%,
    26.4% 4.65%,
    0% 5.2%,
    0.2% 99.45%,
    71.05% 99.25%,
    82.7% 89.55%,
    100% 89.55%
  );
}
.small-div-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  min-height: 320px;
  transition: all 0.3s ease-in-out;
}
.small-div-img:hover,
.small-div-img-2:hover {
  transform: scale(1.13);
  box-shadow: 0px 0px 10px 3px #053850;
}

.small-div-img-2 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  flex-direction: column;
  object-fit: cover;
  /* min-height: 320px; */
  width: 100%;
  height: auto;
  border: 8px solid #4ebef2;
  position: relative;
}
.small-div-img-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #085d84a8;
  z-index: 1;
}
.small-div-img-2 > * {
  z-index: 2;
}

.it-out-source {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex !important;
  align-items: center !important;

  object-fit: cover;
  min-height: 120vh;
  width: 100%;
  position: relative;
  /* clip-path: polygon(
    0% 15.45%,
    3.2% 7.2%,
    5.95% 0.2%,
    51.35% 0.2%,
    58.5% 0.4%,
    63.1% 11.95%,
    100% 12.55%,
    100% 100%,
    0% 100%
  ); */
}
.backup-content {
  background-color: #085d84b6;
  backdrop-filter: blur(5px);
  padding-bottom: 100px;
}
.backup-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex !important;
  /* align-content:space-between !important; */
  align-content: space-between;
  object-fit: cover;
  min-height: 110vh;
  width: 100%;
  position: relative;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 100%,
    14.7% 100.2%,
    9.5% 87.1%,
    7.15% 87.1%,
    0% 87.1%
  );
}
.it-out-source::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #085d84cb;
  z-index: 0;
}
.it-out-source > * {
  z-index: 2;
}

.v-small-text {
  font-size: 12px !important;
}

.navbar-top {
  background-color: white !important;
  color: var(--primary-color) !important;
  opacity: 0.76;
}
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--primary-color) !important;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 999999999;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
.about-div-clip {
  overflow: visible;
  /* clip-path: polygon(
    0% 12.65%,
    10.95% 0.3%,
    59.5% 0.3%,
    64.5% 9.4%,
    103.35% 9.7%,
    104.4% 100%,
    0% 100%
  ); */
  margin-top: -50px;
  background-color: #085c84;
}
.cookie-banner p {
  margin: 0;
  padding-right: 1rem;
}
.justify {
  text-align: justify !important;
}
.cliped-row {
  background-color: white;
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 100px;
  margin-bottom: -5px !important;
  clip-path: polygon(
    8.45% 0%,
    43.55% 0%,
    51.8% 73%,
    85.05% 73%,
    88.6% 100%,
    116.4% 100%,
    116.15% 100%,
    0% 100%
  );
}
.cliped-row-v {
  background-color: white;
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 100px;
  clip-path: polygon(
    8.45% 100%,
    43.55% 100%,
    51.8% 27%,
    85.05% 27%,
    88.6% 0%,
    116.4% 0%,
    116.15% 0%,
    0% 0%
  );
}

.cliped-row-reverse {
  background-color: white;
  margin: 0px !important;
  padding: 0px !important;
  margin-bottom: -5px !important;
  width: 100%;
  height: 100px;
  clip-path: polygon(
    91.55% 0%,
    56.45% 0%,
    48.2% 73%,
    14.95% 73%,
    11.4% 100%,
    -16.4% 100%,
    -16.15% 100%,
    100% 100%
  );
}
.bottom-cliped-row {
  padding-top: -100px !important;
  margin: 0px !important;
  width: 100% !important;
  height: 100px;
  background-color: blue;
}
.bottom-cliped {
  background-image: radial-gradient(
    circle at right,
    var(--primary-color) 0%,
    #32cbd0 100%
  );
  background-repeat: no-repeat;

  clip-path: polygon(
    8.45% 1%,
    56.45% 1%,
    65.95% 100%,
    109.95% 99%,
    116.15% 0%,
    116.15% 100%,
    0% 100%
  );
}

.about-us {
  /* margin-top:-100px */
  background-image: radial-gradient(
    circle at right,
    var(--primary-color) 0%,
    #32cbd0 100%
  );
  background-repeat: no-repeat;
  margin-top: -2px;
  padding-bottom: 130px !important;
}
.business-solution {
  background-image: radial-gradient(
    circle at left,
    var(--primary-color) 0%,
    #32cbd0 100%
  );
  clip-path: polygon(
    0% 0%,
    148.8% 0%,
    148.8% 100%,
    15.85% 100%,
    9.95% 90.15%,
    -1.1% 90.15%
  );
}
.bg-image {
  background-image: url("/public/img/4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 3px solid var(--primary-color);
}

.grid-item {
  min-height: 200px; /* Adjust the height as needed */
  border: 3px solid var(--primary-color);
}
.business-bottom-clip {
  height: 100px;
  width: 100%;
  background-image: radial-gradient(
    circle at left,
    var(--primary-color) 0%,
    #32cbd0 100%
  );
}
.custom-input {
  border: none;
  border-bottom: 1px solid var(--primary-color);
  outline: none;
  box-shadow: none;
  border-radius: 0px;
}

.custom-input:focus {
  border-color: inherit;
  box-shadow: none;
}
.contact-us-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left: -150px;
}
.contact-form {
  /* margin-right: -150px */
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-to-top__button {
  background-color: #000;
  color: #fff;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.673);
}

.scroll-to-top__button:hover {
  opacity: 1;
}
.team {
  clip-path: polygon(
    0% 0%,
    58.55% 0%,
    65.55% 9.6%,
    195.35% 9.35%,
    195.55% 174.1%,
    0% 174.1%
  );
  margin-top: -100px;
}
#cybersecurity {
  clip-path: polygon(
    13.5% 100.55%,
    8.5% 91.45%,
    0% 91.75%,
    0% 0%,
    58.55% 0%,
    65.6% 9.6%,
    195.4% 9.35%,
    195.6% 174.1%,
    0% 174.1%
  );
}
#ar {
  margin-top: -100px;
  clip-path: polygon(
    86.5% 100.55%,
    91.5% 91.45%,
    100% 91.75%,
    100% 0%,
    75.45% 0%,
    70.4% 9.6%,
    -95.4% 9.35%,
    -95.6% 174.1%,
    100% 174.1%
  );
}
#ar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    2,
    45,
    90,
    0.447
  ); /* Adjust the opacity value (0.5) as needed */
  backdrop-filter: blur(3px); /* Adjust the blur value (5px) as needed */
  z-index: 0;
}
.ar > * {
  z-index: 22;
}
/* Responsive Clip PAths */
.cliped-row-it {
  background-color: white;
  margin: 0px !important;
  padding: 0px !important;
  margin-top: -5px !important;
  width: 100%;
  height: 100px;
  clip-path: polygon(
    9.5% -26%,
    44.8% -118%,
    62.7% 41%,
    97.75% 41%,
    103.4% -82%,
    116.4% 0%,
    116.2% 0%,
    0% 0%
  );
}
.cliped-row-it-x {
  background-color: white;
  margin: 0px !important;
  margin-top: -5px !important;
  padding: 0px !important;
  width: 100%;
  height: 100px;
  clip-path: polygon(
    90.5% -26%,
    55.2% -118%,
    37.3% 41%,
    2.25% 41%,
    -3.4% -82%,
    -16.4% 0%,
    -16.2% 0%,
    100% 0%
  );
}
@media (max-width: 786px) {
  .cliped-row,
  .cliped-row-v,
  .cliped-row-it {
    height: 45px !important;
  }
  .cliped-row-reverse {
    height: 45px !important;
  }
  .bottom-cliped {
    height: 30px !important;
  }
  .team {
    clip-path: polygon(
      0% 0%,
      40.25% 0%,
      43.95% 4.4%,
      133.95% 4.8%,
      134.1% 173.25%,
      0% 173.25%
    ) !important;
  }
  .business-solution {
    clip-path: polygon(
      0% 0%,
      149% 0%,
      149% 100%,
      15.85% 100%,
      10.65% 97.4%,
      0% 97.45%,
      -0.9% 90.2%
    ) !important;
  }
  #cybersecurity {
    clip-path: polygon(
      0% 0%,
      40.25% 0%,
      43.95% 4.4%,
      133.95% 4.8%,
      134.1% 173.25%,
      0% 173.25%
    ) !important;
  }

  .contact-us-img {
    margin: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
    min-height: 340px;
  }
  .contact-form {
    margin-left: auto !important;
    margin-right: auto !important ;
    width: 90%;
  }
  .contact-wrapper {
    width: 100%;
  }
}
button {
  box-shadow: 0px 0px 5px 2px var(--primary-color);
}
.it-out-content {
  /* margin-top: -200px !important; */
}
.top-cookie-bg {
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  min-height: 60vh;
  background-color: #000000d7;
  height: fit-content;
}

.top-cookie-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at left, #083434 50%, #48d2d763 100%);

  min-height: 60vh;
  z-index: 1;
}
.top-cookie-bg > * {
  z-index: 2 !important;
}
.cookie-heading {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white !important;
  font-weight: 700;
}
.top-cookie-heading {
  width: 100% !important;
  background-image: radial-gradient(circle at left, #088c84 0%, #32cbd000 95%);
}
.a-li{
  background-color: blue !important;
}